<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="info" v-if="paramsId">
        <span class="mr-20">所属门店：{{form.shopName}}</span>
        <span class="mr-20">燃料类型：{{form.fuelTypeName}}</span>
        <span class="mr-20">累计里程（km）：{{form.totalMileage}}</span>
      </div>
      <div slot="content">
        <a-card title="车辆信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
              <a-row>
                <a-col :span="10">
                  <a-form-model-item label="车辆名称" prop="kartName">
                    <a-input v-model="form.kartName" :maxLength="50" allowClear :disabled="disableFlag" placeholder="请输入车辆名称"></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="10">
                  <a-form-model-item label="车辆编码" prop="kartCode">
                    <a-input v-model="form.kartCode" :maxLength="50" allowClear :disabled="disableFlag" placeholder="请输入车辆编码"></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="车辆类型" prop="kartClassifyId">-->
<!--                  <a-select v-model="form.kartClassifyId" allowClear placeholder="请选择" :disabled="isDetail">-->
<!--                    <a-select-option v-for="item in vehicleCategoryList" :key="item.kartClassifyId" :value="item.kartClassifyId">-->
<!--                      {{item.kartClassifyName}}-->
<!--                    </a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="状态" prop="kartStatus">-->
<!--                  <a-select v-model="form.kartStatus" allowClear :disabled="isDetail" placeholder="请选择">-->
<!--                    <a-select-option :value="0">-->
<!--                      启用-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="1">-->
<!--                      禁用-->
<!--                    </a-select-option>-->
<!--                    <a-select-option :value="2">-->
<!--                      维护中-->
<!--                    </a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车辆类别" prop="carCategoryId">
                  <j-category-car-select
                    placeholder="请选择分类"
                    v-model="form.carCategoryId"
                    :disabled="isDetail"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="备注">
                  <a-input v-model="form.remark" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" allowClear :maxLength="100" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { vehicleInfo, vehicleSubmit } from '@/api/vehicle';
import formMixin from '@/mixin/formMixin';
import JCategoryCarSelect from '@/components/JCategoryCarSelect';
import { loadCategoryDictData } from '@/api/manage';

export default {
  name: 'VehicleForm',
  mixins: [formMixin],
  components: {
    JCategoryCarSelect,
  },
  props: {},
  data() {
    return {
      vehicleCategoryList: [],
      form: {
        kartName: '',
        kartCode: '',
        kartStatus: 0,
        kartClassifyId: '',
        remark: '',
        carCategoryId: null,
      },
      dictOptions: {},
      rules: {
        kartName: [
          {
            required: true,
            message: '请输入车辆名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 50,
            message: '长度在1-50字之间',
            trigger: 'blur'
          }
        ],
        kartClassifyId: [
          {
            required: true,
            message: '请选择车辆类型'
          }
        ],
        kartStatus: [
          {
            required: true,
            message: '请选择车辆状态'
          }
        ],
      },
      disableFlag: false,
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    }
  },
  watch: {},
  created() {
    // this.getVehicleCategoryList();
    this.initDictConfig();
    if (this.paramsId) {
      this.disableFlag = true;
      this.getData(this.paramsId);
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取信息
    getData(id) {
      vehicleInfo(id).then((data) => {
        this.form = data;
      });
    },
    // 保存
    handleSave() {
      const params = { ...this.form };
      vehicleSubmit(params).then(() => {
        this.$message.success('操作成功！');
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
    // 车辆类型列表
    // getVehicleCategoryList() {
    //   vehicleAllCateGoryList().then(({ list }) => {
    //     this.vehicleCategoryList = list;
    //   });
    // },
    initDictConfig() {
      loadCategoryDictData({ code: '0' }).then((res) => {
        // console.log(res)
        if (res !== undefined) {
          this.$set(this.dictOptions, 'carCategoryId', res);
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
